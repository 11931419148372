import {
  postNativeMessage,
  useOnNativeMessage,
} from "../foundation/native-bridge";
import { MathpixAuth } from "./api";

export function setCanvasVisible(visible: boolean) {
  postNativeMessage({
    type: "canvasVisible",
    value: visible,
  });
}

export function setTool(tool: ToolType) {
  postNativeMessage({
    type: "tool",
    value: tool,
  });
}

export function setMathpixAuth(auth: MathpixAuth) {
  postNativeMessage({
    type: "mathpixAuth",
    value: auth,
  });
}

export function undo() {
  postNativeMessage({
    type: "undo",
  });
}

export function redo() {
  postNativeMessage({
    type: "redo",
  });
}

export function setDrawing(drawing: string | null) {
  postNativeMessage({
    type: "drawing",
    value: drawing,
  });
}

export function useOnToolMessage(callback: (message: ToolMessage) => void) {
  return useOnNativeMessage((message: Record<string, unknown>) => {
    if (message.type === "tool") {
      callback(message as ToolMessage);
    }
  });
}

export function useOnDrawingMessage(
  callback: (message: DrawingMessage) => void,
) {
  return useOnNativeMessage((message: Record<string, unknown>) => {
    if (message.type === "drawing") {
      callback(message as DrawingMessage);
    }
  });
}

export function useOnOutputMessage(callback: (message: OutputMessage) => void) {
  return useOnNativeMessage((message: Record<string, unknown>) => {
    if (message.type === "output") {
      callback(message as OutputMessage);
    }
  });
}

export function useOnImageOutputMessage(
  callback: (message: ImageOutputMessage) => void,
) {
  return useOnNativeMessage((message: Record<string, unknown>) => {
    if (message.type === "image_output") {
      callback(message as ImageOutputMessage);
    }
  });
}

export type ToolType = "pen" | "eraser" | "lasso";

export type ToolMessage = {
  type: "tool";
  value: ToolType;
};

export type DrawingMessage = {
  type: "drawing";
  value: string;
};

export type OutputMessage = {
  type: "output";
  value: string;
};

export type ImageOutputMessage = {
  type: "image_output";
  value?: string | null;
};
