import { createContext, useContext, useState } from "react";
import { useMemoryPNode } from "../foundation/p-node/memory";
import { PNode } from "../foundation/p-node/p-node";
import { AcademicSeason } from "../foundation/exam-specifier";

export interface DraftUploadForm {
  name: string;
  images1: Blob[];
  images2?: Blob[];
  images1IsSolution: boolean;
}

export interface DraftMovingForm {
  year: number;
  season: AcademicSeason;
  professors: string[];
}

export type DraftFormData = Partial<DraftUploadForm & DraftMovingForm>;

const DraftFormDataContext = createContext<PNode<DraftFormData> | undefined>(
  undefined,
);

export function DraftFormDataProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const formDataNode = useMemoryPNode<DraftFormData>({});

  console.log("formDataNode", formDataNode.value);

  return (
    <DraftFormDataContext.Provider value={formDataNode}>
      {children}
    </DraftFormDataContext.Provider>
  );
}

export function useDraftFormDataNode() {
  const formDataNode = useContext(DraftFormDataContext);
  if (!formDataNode) {
    throw new Error(
      "useDraftFormData must be used within a DraftFormDataProvider",
    );
  }

  return formDataNode;
}
